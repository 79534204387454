import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "assets/icons/down-arrow.svg";
import BottomImg from "assets/images/bottom.svg";
import CareImg from "assets/images/care.svg";
import CompanyImg from "assets/images/company.svg";
import LighthouseImg from "assets/images/lighthouse.svg";
import MobileImg from "assets/images/mobileheart.svg";
import OptumLogo from "assets/optum-logo.svg";
import Anchor, { LinkButton } from "atom/Anchor";
import Button from "atom/Button";
import Input from "atom/Input";
import Icon from "atom/Icon";
import PageCenter from "atom/PageCenter";
import SelectLanguage from "atom/SelectLanguage";
import { Heading } from "atom/Typography";
import AccessCodeProvider from "provider/AccessCodeProvider";
import cn from "classnames";
import style from "./style.scss";

//provider - company name, language

const info = [
  {
    img: <CareImg />,
    title: "Find expert care",
    subtitle:
      "Search for a provider and get recommendations to find the right care for you",
  },
  {
    img: <LighthouseImg />,
    title: "Explore your benefits",
    subtitle:
      "Check out tools, programs and services included in your benefits package",
  },
  {
    img: <MobileImg />,
    title: "Crisis support",
    subtitle:
      "When you’re facing a crisis situation, we’re here with the resources and support to help",
  },
];




const validate = ({ company }) => {
  const errors = [];
  if (!(company !== "" && ["Premium", "OptumBH", "OptumEWS"].includes(company))) {
    errors.push("Company doesn't exist");
  }

  return errors;
};

export default () => {
  let navigate = useNavigate();
  const [language, setLanguage] = useState("");
  const [company, setCompany] = useState("");
  const [validation, setValidation] = useState([]);

  const handleSubmit = e => {
    const errors = validate({ company });
    if (errors.length === 0) {
      onSubmit(company);
    } else {
      setValidation(errors);
    }
  };

  const handleKeyDown = e => {
    if (e.code === "Enter") {
      handleSubmit();
    }
  };

  const onSubmit = value => {
    AccessCodeProvider.setAccessCode(value);
    if (["OptumBH"].includes(value)) navigate(`/company/${value}`);
    else if (["OptumEWS", "Premium"].includes(value)) navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageCenter
        rootClass={cn(style.header_root)}
        contentClass={cn(style.header)}
      >
        <div className={cn(style.side)}>
          <OptumLogo />
          Live and Work Well
        </div>
        <div className={cn(style.side)}>
          <SelectLanguage
            handleChange={e => setLanguage(e.target.value)}
            selected={language}
            simple={true}
          />
          <LinkButton
            className={cn(style.linkButton)}
            onClick={() => null}
            TagName="div"
          >
            Sign in
          </LinkButton>
          <LinkButton
            className={cn(style.reg)}
            onClick={() => null}
            TagName="div"
          >
            Register
          </LinkButton>
        </div>
      </PageCenter>

      <PageCenter
        rootClass={cn(style.banner_root)}
        contentClass={cn(style.banner)}
      >
        <BottomImg className={cn(style.bg)} />
        <div className={cn(style.main)}>
          <div className={cn(style.text)}>
            <Heading className={cn(style.title)}>
              {"Care and support for \n your emotional wellbeing"}
            </Heading>
            <Heading type={"h4"} className={cn(style.subtitle)}>
              {"Enter your access code here"} <Icon icon={"info"} />
            </Heading>
            <label>
              Don't know it? <Anchor href={null}>Browse by company</Anchor>
            </label>
            <div className={cn(style.company)}>
              <Input
                className={cn(style.input)}
                type="text"
                id="company"
                aria-describedby="company_err"
                value={company}
                maxLength={40}
                onChange={e => {
                  setValidation([]);
                  setCompany(e.target.value);
                }}
                placeholder="Enter company name"
                validation={validation}
                onKeyDown={handleKeyDown}
              />
              <Button text="Enter" onClick={handleSubmit} />
            </div>
            <LinkButton
              className={cn(style.healthsafe)}
              onClick={null}
              // TagName="div"
            >
              {`Or sign in with HealthSafe ID >`}
            </LinkButton>
          </div>
          <div className={cn(style.bannerimg)}>
            <CompanyImg />
          </div>
        </div>
        <div className={cn(style.bottom)}>
          <div>
            <Icon icon={"phone"} />
            <strong>Need help now?</strong>&nbsp;
          </div>
          <div>
            For a mental health crisis call or text&nbsp;<strong>988</strong>
          </div>
          <LinkButton
            className={cn(style.options)}
            onClick={null}
            TagName="div"
          >
            More options <Arrow />
          </LinkButton>
        </div>
      </PageCenter>
      <PageCenter contentClass={cn(style.information)}>
        <Heading type={"h3"} centered>
          {"Sign in to Live and Work Well to get the \n support you need"}
        </Heading>
        <div className={cn(style.items)}>
          {info.map(({ img, title, subtitle }, i) => (
            <div key={i} className={cn(style.item)}>
              {img}
              <Heading type={"h4"} centered>
                {title}
              </Heading>
              <p>{subtitle}</p>
            </div>
          ))}
        </div>
      </PageCenter>
    </>
  );
};

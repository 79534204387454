import React, {useState} from "react";
import { LinkButton } from "atom/Anchor";
import style from "./style.scss";
import cn from "classnames";
import Banner from "molecule/Banner";
import HomeBanner from "molecule/HomeBanner";
import ModalCreateFreeAccount from "molecule/ModalCreateFreeAccount";

const spotlightbg = {
  src: require("/assets/images/spotlightbg.png"),
  placeholder: require("/assets/images/spotlightbg_thumb.png"),
  height: 546,
  width: 1366,
};

const cards = [
  {
    text:
      "Diversity brings fresh perspectives. And it brings new ideas that benefit our lives, communities and workplaces.",
    linktext: "Learn more",
  },
  {
    text:
      "Emotional Wellbeing Solutions is more than your traditional employee assistance program (EAP).",
    linktext: "Learn more",
  },
  {
    text:
      "Get support for everyday life with confidential & no-cost Virtual Visits. Explore our 24/7 network of coaches & therapists, with just a click.",
    linktext: "Book a virtual visit",
  },
  {
    text:
      "It takes less than 15 minutes—or 1% of your day—to develop healthy habits with mindfulness practice.",
    linktext: "Create a free account",
  },
];

const cards2 = [
  {
    text:
      "<b>Stress less. Sleep more. Live mindfully.</b>All of Calm's tools - meditations, breathing exercises ...",
    target: "https://www.calm.com/b2b/ews/subscribe?code=optumeap",
    background: require("/assets/images/spotlightbg_calm.jpg"),
    buttonText: "Get the app",
  },
  {
    text: "<b>Explore your Financial Wellbeing Resources.</b>",
    target: "https://academy.brightplan.com/?countrycode=us&gl=1&company_code=globaldemo",
    background: require("/assets/images/spotlightbg_brightplan.jpg"),
    buttonText: "Get Started!",
  },
  {
    text:
      "This month, we explore suicide, substance use disorders and provide resources to support you and those close to you.",
    target: "https://optumwellbeing.com/en-US",
    background: require("/assets/images/spotlight_toolkit.jpg"),
    buttonText: "Learn more",
  },
];

export default ({}) => {

  const [showModal, setShowModal] = useState(false)
  
  return (
  <>
    {/* <Banner image={spotlightbg} full>
      <div className={cn(style.cards)}>
        {cards.map(({ text, linktext }, i) => (
          <div className={cn(style.item)} key={i}>
            <p>{text}</p>
            <LinkButton>{`${linktext} >`}</LinkButton>
          </div>
        ))}
      </div>
    </Banner> */}
    <HomeBanner
      home={[
        ...cards2,
        {
          text:
            "<b>10 no-cost counseling visits</b>Get in-network therapy visits - no cost or copay. Learn how this benefit works and how to use it.",
          background: require("/assets/images/spotlightbg.png"),
          buttonText: "Get the details",
          onClick: () => {
            setShowModal(true)
          },
        },
      ]}
    />

    {showModal && (
      <ModalCreateFreeAccount onClose={() => setShowModal(false)} />
    )}
  </>
)};

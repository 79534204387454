import React, { createContext, useEffect, useState, useContext } from "react";
import _ from "lodash";
import theme from "data/theme.js";
// import { GeneralContext } from "provider/GeneralProvider";

export const ThemeContext = createContext(null);

const CONTACTS = {
  "en-US": {
    phone_getHelp: "1-877-510-9664",
    phone_text: ` If you or a loved one has a mental health or substance use crisis, call
        or text 988 to connect with the 988 Suicide and Crisis Lifeline. If you
        have an immediate, life-threatening emergency, call 911.`,
    phone_crisis: "988",
  },
  default: {
    phone_getHelp: "+44-1865-397-074",
    phone_text: `If you or a loved one has a mental health or substance use crisis, call or text 111 to connect with the 111 Suicide and Crisis Lifeline.`,
    phone_crisis: "111",
  },
};

export const ThemeProvider = ({ children, ...props }) => {
  const [vars, setVars] = useState({});
  const [logo, setLogo] = useState("");
  const [data, setData] = useState({
    language: "en-US",
  });

  // const contextData = useContext(GeneralContext);
  //const theme_overrides = contextData?.content?.theme?.theme_overrides || {};

  useEffect(() => {
    const brand = data?.content?.brand;

    const _theme = theme[brand] || theme.default;
    if (_theme) {
      setVars(_theme.vars);
      setLogo(_theme.logo);
    }
  }, [data?.content?.brand]);

  useEffect(() => {
    setData(prev => {
      const _v = _.cloneDeep(prev);
      const contacts = CONTACTS[data?.language] || CONTACTS.default;
      _v.contacts = contacts;
      return _v;
    });
  }, [data?.language]);

  const themeDefault = {};
  return (
    <ThemeContext.Provider value={{ logo, data, setData }}>
      <div style={{ ...themeDefault, ...vars }} {...props}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
